<template>
  <div>
    <p class="title">Central de Mensagens</p>
    <div class="row">
      <div class="content">
        <div v-show="messages.length" :class="`content_left ${!!selectedEmprestimoId ? 'hide_on_mobile' : 'content_left--mobile'}`">
          <ul>
            <li class="message_list" :class="!message.algumaSemLer ? 'opacity-75' : 'font-bold'" v-for="message of messages" :key="message.emprestimoId" @click="showMessages(message.emprestimoId)">
              <div class="message_block" :class="message.emprestimoId === selectedEmprestimoId ? 'message_block_active' : ''">
                <p class="message_title">{{message.empresa}} <sup>{{message.dataOperacao | moment('DD/MM/YYYY')}}</sup></p>
                <p class="message_date" style="font-weight: normal;">Último status em {{message.dataUltimaMensagem | moment('DD/MM/YYYY [às] HH:mm')}}</p>
                <p v-if="message.algumaSemLer" style="font-weight: normal;" class="message_date">{{message.mensagens.filter(x=> !x.aberto).length}} {{(message.mensagens.filter(x=> !x.aberto).length) == 1 ? 'nova mensagem' : 'novas mensagens'}}</p>

              </div>
            </li>
          </ul>
        </div>
        <div :class="messages.length ? `content_right ${!selectedEmprestimoId ? 'hide_on_mobile' : 'content_right--mobile'}` : 'content_all'">
          <div  v-if="selectedEmprestimoId">
           <p class="message_title">{{messages.find(x=>x.emprestimoId == selectedEmprestimoId).empresa}} <small>{{messages.find(x=>x.emprestimoId == selectedEmprestimoId).dataOperacao | moment('DD/MM/YYYY')}}</small></p>

            <div  v-for="message in currentMessages" v-bind:key="message.dataEnvio" class="bg-gray-300 w-4/4 mx-4 my-2 p-2 rounded-lg clearfix">
              <span class="close" @click="selectedEmprestimoId = null">
                <i class="fas fa-times"></i>
              </span>
              <p v-html="message.mensagem" class="text-sm opacity-75"></p>
              <p class="text-sm text-right text-gray-700 mb-2">{{message.dataEnvio | moment('DD/MM/YYYY [às] HH:mm') }}</p>

            </div>
         </div>
         <div v-else-if="!loading && !selectedEmprestimoId" class="h-full flex justify-center items-center">Selecione uma mensagem para visualizá-la</div>
            <div v-if="loading" class="h-full flex justify-center items-center">
              <i class="fas fa-spinner fa-spin text-secondary"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Messages from '@/services/Investor/Messages/Messages'

export default {
  name: 'investor-messages',
  data () {
    return {
      messages: [],
      selectedMessage: null,
      selectedMessageData: null,
      loading: true,
      selectedEmprestimoId : null
    }
  },
  computed: {
    messageClass (message) {
      const className = []
      if (message.aberto) className.push('font-bold')
      else className.push('opacity-75')
      if (message.id === this.selectedMessage) className.push('message_block_active')
      return className.join(' ')
    },
    currentMessages(){
      if(!this.selectedEmprestimoId) return [];

      return this.messages.find(x=> x.emprestimoId == this.selectedEmprestimoId).mensagens;
    }
  },
  methods: {
    async getMessages () {
      try {
        this.loading = true
        const response = (await Messages.getMessages()).data.data
        this.messages = response
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async showMessages(emprestimoId){
      this.selectedEmprestimoId = emprestimoId;
      this.loading = false;
      var itens = this.currentMessages.filter(x=> !x.aberto);

      for(var i = 0; i < itens.length; i ++){
        await Messages.getMessage(itens[i].id)
      }

      this.messages =  (await Messages.getMessages()).data.data;
      this.$emit('updateMessages')
    },
    async selectMessage (id) {
      if (this.selectedMessage === id) return
      this.selectedMessage = id
      this.loading = true
      this.selectedMessageData = (await Messages.getMessage(id)).data.data
      this.loading = false
      this.$emit('updateMessages')
      this.getMessages()
    }
  },
  mounted () {
    this.getMessages()
  }
}
</script>

<style lang="sass" scoped>

.row
  @apply w-full flex flex-col
  @screen md
    @apply flex-row

.title
  @apply font-bold text-xl text-secondary my-4 w-full
  @screen lg
    @apply text-2xl

.content
  @apply w-full bg-white shadow border rounded-lg flex mb-16
  height: 75vh
  @screen lg
    @apply mb-0
  &_all
    @apply w-full
  &_left
    @apply w-1/3 h-full border-r-2 overflow-y-auto
    &--mobile
      @apply w-full
      @screen lg
        @apply w-1/3
  &_right
    @apply w-2/3 h-full p-3 relative overflow-y-auto
    &--mobile
      @apply w-full
      position: absolute
      heigth: 100vh
      top: 0
      left: 0
      background-color: #fff
      z-index: 55
      @screen lg
        position: relative
        @apply w-2/3

.hide_on_mobile
  @apply hidden
  @screen lg
    @apply block

.message
  &_block
    @apply h-20 border-b flex flex-col justify-center px-4
    @screen md
      @apply
    &:hover
      @apply cursor-pointer
      background-color: rgba(72, 27, 144, 0.1)
    &_active
      background-color: rgba(72, 27, 144, 0.2)
  &_list
    @apply opacity-75
    @screen md
      @apply
    &:first-child,
    &:first-child .message_block:hover,
    &:first-child .message_block_active
      @apply rounded-tl-lg
    &:last-child
      @apply rounded-bl-lg
  &_title
    @apply text-xs text-center
    @screen md
      @apply text-sm text-left
  &_date
    @apply text-xss
    @screen md
      @apply text-xs

.close
  @apply absolute right-0 top-0 text-secondary rounded-full cursor-pointer z-10 m-2
  @screen lg
    @apply px-1

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary

</style>
