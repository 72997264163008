import api from '@/plugins/axios'

const base = 'v1/investidor/central-mensagens/'

export default {
  getMessages () {
    return api.get(base)
  },
  getMessage (id) {
    return api.get(`${base}${id}`)
  }
}
